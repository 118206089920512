tbody tr[data-v-109d109a] {
  background-color: var(--white-clr);
  border: 10px solid var(--chat-background-clr);
}
.checkbox[data-v-109d109a] {
  display: none;
}
tr[data-v-109d109a] {
  width: 100%;
}
thead[data-v-109d109a] {
  width: 100px;
}
.vector-modal-btn[data-v-109d109a] {
  background-color: transparent;
  border: 0;
  outline: 0;
}
.flex-vector-methods[data-v-109d109a] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}
.flex-vector-methods button[data-v-109d109a] {
  font-size: 18px;
  display: flex;
  gap: 16px;
}
thead th[data-v-109d109a] {
  position: -webkit-sticky;
  position: sticky;
  /* font-family: 'Courier New', Courier, monospace; */
  top: 0; /* Adjust this if you have any fixed element at the top of the page */
  z-index: 2;
  background-color: var(--chat-background-clr);
  padding: 13px 10px;
}
tr img[data-v-109d109a] {
  width: 18px;
  height: 18px;
}
td[data-v-109d109a],
th[data-v-109d109a] {
  min-width: 120px;
}
table[data-v-109d109a] {
  border: 0;
  width: 100%;
  table-layout: fixed;
  font-size: 16px;
  border-collapse: collapse;
  font-family: Assistant;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.table-container[data-v-109d109a] {
  /* width: 1300px; */
  /* padding-right: 20px;
      padding-left: 20px; */
  overflow: auto;
  max-height: calc(100vh - 300px);
}
td[data-v-109d109a] {
  border-right: none;
  border-left: none;
  font-size: 18px;
  vertical-align: top;
  padding: 13px 5px;
}
.icon-name-container[data-v-109d109a] {
  display: flex;
  gap: 8px;
  font-family: Assistant;

  align-items: center;
  font-size: 18px;
}
.marked-row[data-v-109d109a] {
  background-color: var(--main-undertone-clr);
}
.select-box-tool-box[data-v-109d109a] {
  direction: ltr;
  width: 190px;
  font-family: Assistant;
  background-color: var(--lighter-grey-clr);
  height: 30px;
  border-radius: 8px;
  outline: none;
  font-size: 16px;
  color: var(--text-clr);
}
button[data-v-109d109a] {
  background-color: var(--white-clr);
}
.pagination-container[data-v-109d109a] {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
}
