.page-header[data-v-beec8783]{
        background-color: var(--white-clr);
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
        height: 60px;
        padding: 0px 10px;
        align-items: center;
        /* z-index: 5; */
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
}
.MuiTabs-indicator[data-v-beec8783]{
    height: 3px;
}
.user-container[data-v-beec8783]{
    margin-right:10px;
    display: flex;
    align-items: center;
    gap: 15px;
    flex-direction: row;
}
.user-bubble[data-v-beec8783]{
    height: 32px;
    width: 32px;
    border-radius: 50%;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background-color: var(--main-undertone-clr);
    color: var(--text-clr);
}
.username-title[data-v-beec8783]{
    font-family: Assistant;
font-size: 16px;
font-style: normal;
color: var(--text-clr);
font-weight: 600;
}
.logo-and-steps[data-v-beec8783]{
    display: flex;
    gap: 20px;
    height: 100%;
}
.flex-links[data-v-beec8783]{
    display: flex;
    /* align-items: center; */
    flex-direction: row;
    /* gap: 20px; */
}
.active-link[data-v-beec8783]{
    color: var(--main-clr);
    background-color: var(--main-undertone-clr);
    border-bottom: 2px solid var(--main-clr);
}
.flex-links a[data-v-beec8783]{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100px;
    text-align: center;
    color: var(--text-clr);
    /* background-color: var(--main-undertone-clr); */
    /* justify-content: center; */
    text-decoration: none;
    align-self: center;
    /* padding: 10px 10px/; */
}
.header-link img[data-v-beec8783]{
    height: 100%;
    width: 100%;
    position: relative;
}
.header-link[data-v-beec8783]{
    position: relative;
    width: 98px;
    align-self: center;
    height: 22px;
}
.user-container p[data-v-beec8783]{
     font-family: Assistant;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
}

