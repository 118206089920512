tbody tr[data-v-3c0b1cfd]{
    background-color: var(--white-clr);
    border: 10px solid var(--chat-background-clr);
}
.checkbox[data-v-3c0b1cfd]{
    display: none;
}
tr[data-v-3c0b1cfd]{ width: 100%;}
thead[data-v-3c0b1cfd]{
    width: 100px;
}
.vector-modal-btn[data-v-3c0b1cfd]{
    background-color: transparent;
    border: 0;
    outline: 0;
}
.flex-vector-methods[data-v-3c0b1cfd]{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
}
.flex-vector-methods button[data-v-3c0b1cfd]{
    font-size: 18px;
    display: flex;
    gap: 16px;
}
thead th[data-v-3c0b1cfd] {
    position: -webkit-sticky;
    position: sticky;
    /* font-family: 'Courier New', Courier, monospace; */
    top: 0;  /* Adjust this if you have any fixed element at the top of the page */
    z-index: 2;
    background-color: var(--chat-background-clr);
    padding: 13px 10px;
}
tr img[data-v-3c0b1cfd]{
    width:18px;
    height: 18px;
}
td[data-v-3c0b1cfd], th[data-v-3c0b1cfd]{
    min-width: 120px;
}
table[data-v-3c0b1cfd]{
    border: 0;
    width: 100%;
    table-layout: fixed;
    font-size: 16px;
    border-collapse: collapse;
    font-family: Assistant;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.table-container[data-v-3c0b1cfd]{
    /* width: 1300px; */
    /* padding-right: 20px;
    padding-left: 20px; */
    overflow: auto;
    max-height: calc(100vh - 215px);
}
td[data-v-3c0b1cfd]{
    border-right: none;
    border-left: none;
    font-size: 18px;
    /* vertical-align: top; */
    padding: 13px 5px;
}
.icon-name-container[data-v-3c0b1cfd]{
    display: flex;
    gap: 8px;
    font-family: Assistant;

    align-items: center;
    font-size: 18px;
}
.marked-row[data-v-3c0b1cfd]{
    background-color:var(--main-undertone-clr) ;
}