.main-content-container[data-v-49d5c1ae] {
  flex-grow: 1;
  padding-top: 20px;
  padding-right: 40px;
  padding-bottom: 20px;
  padding-left: 15px;
  position: relative;
  margin-top: 50px;
}
@media only screen and (max-width: 600px) {
.question-container[data-v-49d5c1ae] {
    padding: 5px !important;
}
}
.main-page-container[data-v-49d5c1ae] {
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  justify-content: flex-end;
  flex-direction: row;
}
.question-container[data-v-49d5c1ae] {
  display: flex;
  padding-top: 10px;
  padding-left: 20px;
  gap: 22px;
  padding-right: 36px;
  max-height: 75%;
  overflow-y: auto;
  /* margin-top: 130px; */
  /* old margin before ad */
  align-items: center;
  flex-direction: column-reverse;
}
.question-container[data-v-49d5c1ae]::-webkit-scrollbar {
  display: none;
}
.ans-que-container[data-v-49d5c1ae] {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0px auto 0;
  height: calc(100vh - 200px);
  justify-content: flex-start;
}
.answer-text[data-v-49d5c1ae] {
  direction: rtl;
  display: none !important;
  /* text-align: right; */
  width: 100%;
  margin-bottom: 30px;
  flex-direction: column;
  overflow-y: auto;
  display: flex;
  /* overflow-x: hidden; */
}
.single-ans-que-container[data-v-49d5c1ae] {
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  padding: 0 22px 25px;
  margin-top: 15px;
  background-color: var(--white-clr);
  position: relative;
}
.single-ans-que-container h4[data-v-49d5c1ae],
p[data-v-49d5c1ae] {
  color: var(--black-clr);
}
.dont-show[data-v-49d5c1ae] {
  position: relative;
  opacity: 0;
  z-index: -10;
  height: 0;
}
.speak-icon[data-v-49d5c1ae] {
  position: relative;
  bottom: -20px;
}
.speak-icon[data-v-49d5c1ae],
.active[data-v-49d5c1ae] {
  width: 19px;
  height: 19px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.add-message-btn[data-v-49d5c1ae] {
  cursor: pointer;
  color: var(--text-clr);
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-self: flex-start;
  align-items: center;
  gap: 10px;
  line-height: 20px;
  background-color: transparent;
  border: none;
}
.preview-bg[data-v-49d5c1ae] {
  background: rgba(0, 0, 0, 0.15);
}
.log-details[data-v-49d5c1ae]{
  display: flex;
  gap: 5px;
  flex-direction: column;
}
.log-date[data-v-49d5c1ae]{
  font-weight: 600;
  font-size: 16px;
}
.submit-strip[data-v-49d5c1ae] {
  align-items: center;
  position: absolute;
  bottom: 0%;
  padding-left: 20px;
  width: 100%;
  gap: 10px;
  height: 80px;
  background-color: var(--lighter-grey-clr);
  display: flex;
}
.submit-btn[data-v-49d5c1ae] {
  border-radius: 8px;
  display: flex;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border: none;
  font-family: Assistant;
  background-color: var(--main-clr);
  width: 107px;
  height: 32px;
  padding: 6px 22px 6px 23px;
  justify-content: center;
  align-items: center;
}