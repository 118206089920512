tbody tr[data-v-42a894bf] {
  background-color: var(--white-clr);
  border: 10px solid var(--chat-background-clr);
}
.checkbox[data-v-42a894bf] {
  display: none;
}
tr[data-v-42a894bf] {
  width: 100%;
}
thead[data-v-42a894bf] {
  width: 100px;
}
.vector-modal-btn[data-v-42a894bf] {
  background-color: transparent;
  border: 0;
  outline: 0;
}
.flex-vector-methods[data-v-42a894bf] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}
.flex-vector-methods button[data-v-42a894bf] {
  font-size: 18px;
  display: flex;
  gap: 16px;
}
thead th[data-v-42a894bf] {
  position: -webkit-sticky;
  position: sticky;
  /* font-family: 'Courier New', Courier, monospace; */
  top: 0;
  z-index: 2;
  background-color: var(--chat-background-clr);
  padding: 13px 10px;
}
tr img[data-v-42a894bf] {
  width: 18px;
  height: 18px;
}
td[data-v-42a894bf],
th[data-v-42a894bf] {
  min-width: 120px;
  /* position: sticky; */
  transform: translateZ(0);
}
table[data-v-42a894bf] {
  border: 0;
  width: 100%;
  table-layout: fixed;
  font-size: 16px;
  border-collapse: collapse;
  font-family: Assistant;
  font-style: normal;
  font-weight: 400;
  line-height: 0px;
  filter: none;
  transform: translateZ(0);
}
.table-container[data-v-42a894bf] {
  /* width: 1300px; */
  /* padding-right: 20px;
    padding-left: 20px; */
  overflow: auto;
  max-height: calc(100vh - 300px);
}
td[data-v-42a894bf] {
  border-right: none;
  border-left: none;
  font-size: 18px;
  /* vertical-align: top; */
  padding: 10px 5px;
}
.icon-name-container[data-v-42a894bf] {
  display: flex;
  gap: 8px;
  font-family: Assistant;

  align-items: center;
  font-size: 18px;
}
.marked-row[data-v-42a894bf] {
  background-color: var(--main-undertone-clr);
}
.select-box-tool-box[data-v-42a894bf] {
  direction: ltr;
  width: 190px;
  font-family: Assistant;
  background-color: var(--lighter-grey-clr);
  height: 41px;
  border-radius: 8px;
  outline: none;
  font-size: 16px;
  color: var(--text-clr);
  margin-left: 3px;
}
textarea[data-v-42a894bf] {
  height: 41px;
  border-radius: 10px;
  border: 0;
  background: var(--lighter-grey-clr);
  padding: 10px;
  resize: none;
  width: 200px;
}
textarea[data-v-42a894bf]:focus { 
  outline: none !important;
  /* outline-color: var(--main-clr); */
  /* border-color: #719ECE; */
  box-shadow: 0 0 6px var(--main-clr);
}