p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
li,
strong,
button,
span,
div,
.answer-text {
}
.center-section {
  text-align: center;
  width: 100%;
  margin-top: 20%;
}
html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
}

html {
  background-color: var(--chat-background-clr);
  min-height: 100%;
}
* {
  font-family: Assistant;

  margin: 0;
  box-sizing: border-box;
}

.dont-show {
  position: relative;
  opacity: 0;
  z-index: -10;
  height: 0;
}

svg {
  cursor: pointer;
}

/* a {
  width: 100%;
  text-align: center;
} */

.ag-cell {
  text-align: center;
}

.ag-cell-text-left {
  text-align: left;
}

.ag-header-cell-comp-wrapper {
  justify-content: center;
}

.ag-header-cell-label {
  justify-content: center !important;
}
.ag-root-wrapper {
  overflow: visible !important;
  overflow: initial !important;
}

.rtl {
  direction: rtl !important;
}
.ltr {
  direction: ltr !important;
}

@media only screen and (max-width: 600px) {
  .app {
    flex-direction: column;
  }

  .main-page-container {
    flex-direction: column;
    height: calc(100vh - 90px);
  }
  .mobile-hide {
    display: none;
  }
}

.Typewriter__cursor {
  display: none !important;
} /*# sourceMappingURL=chatPage.css.map */
