.search-container[data-v-13274ee8] {
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: flex-end;
  padding: 0 60px;
  margin-bottom: 35px;
}
.search-input[data-v-13274ee8] {
  background: var(--lighter-grey-clr);
  border: 0;
  font-size: 12px;
  padding: 8px 12px;
  width: 260px;
  border-radius: 8px;
  height:30px;
}
table[data-v-13274ee8] {
  border: 0;
  width: 100%;
  /* table-layout: fixed; */
  font-size: 16px;
  border-collapse: collapse;
  font-family: Assistant;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
thead th[data-v-13274ee8] {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* z-index: 2; */

  padding: 13px 10px;
  width: calc(100% / 8);
  background-color: var(--chat-background-clr);
}
thead[data-v-13274ee8] {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* z-index: 2; */

  /* padding: 13px 10px; */
  /* width: calc(100% / 8); */
  background-color: var(--chat-background-clr);
}
.bot-list-page[data-v-13274ee8] {
  overflow: auto;
  max-height: calc(100vh - 236px);
}
.sort-by-select[data-v-13274ee8] {
  display: flex;
  gap: 5px;
}
img[data-v-13274ee8] {
  position: relative;
  top: 1px;
}
.select-box-tool-box[data-v-13274ee8] {
  width: 200px;
}
#demo-multiple-name-label[data-v-13274ee8] {
  color: var(--black-clr);
  top: -10%;
}
.custom-select-selected[data-v-13274ee8] {
  cursor: pointer;
  gap: 1px;
  border: 0;
  width: 170px;
  border-radius: 8px;
  background: var(--lighter-grey-clr);
  padding: 4px 12px;
  height: 31px;
}
.custom-select-options[data-v-13274ee8] {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background-color: var(--white-clr);
  z-index: 1000;
}
.search-input[data-v-13274ee8] {
  background: var(--lighter-grey-clr);
  border: 0;
  font-size: 14px;
  padding: 8px 12px;
  width: 260px;
  border-radius: 8px;
}
.checkbox[data-v-13274ee8]{
  display: none;
}
/* //     .custom-select-container {
//       position: relative;

//       }


//         .custom-select-option {
//           padding: 10px;
//           cursor: pointer;
//           background-color: var(--lighter-grey-2-clr);
//         }
//       }
//     }

//    
//   }
//   th {
//     width: calc(100% / 7);
//   }
// } */
