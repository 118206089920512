.documents-main-container[data-v-907e1724]{
    /* margin-top: 20px; */
    display: flex;
    flex-direction: column;
}
.tool-kit[data-v-907e1724] {
  background-color: var(--white-clr);
  position: relative;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  width: 315px;
  transition: all 0.2s ease-in;
}
.page-subheader[data-v-907e1724]{
    font-size: 14px;
    display: flex;
     /* justify-content: center; */
    align-items: center; 
    gap:10px;
    color: var(--text-clr);
}
.breadcrumbs[data-v-907e1724]{
  opacity: 0.5;
}
.breadcrumbs-btn[data-v-907e1724]{
  outline: 0;
  border: 0;
  background-color: transparent;
  padding: 0;
}
.breadcrumbs-btn[data-v-907e1724]:hover{
  text-decoration: underline;
  cursor: pointer;
}
.flex-folders-files[data-v-907e1724]{
    display: flex;
    flex-direction: column;
}
.folders-container[data-v-907e1724]{
    padding-left: 20px;
    padding-top: 10px;
    padding-right: 60px;
    padding-bottom: 20px;
}
.close-comment-icon[data-v-907e1724]{
    padding: 0;
    cursor: pointer ;
    /* margin-right: 25px; */
    /* display; */
    background-color: transparent;
    border: none;
}
.bordered-icons[data-v-907e1724]{
    border: 1px solid var(--text-clr);
    padding: 0.2em 0.5em;
    border-radius: 50%;
}