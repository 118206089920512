.outline-btn[data-v-848da4db]{
    border-radius: 8px;
    display: flex;
    font-size: 16px;
    gap: 10px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding: 0.5em 2.5em;
    vertical-align: middle;
    border: 0;
    color: var(--secondary-clr);
    border: 1px solid var(--main-clr);
    background-color:transparent;
}