:root {
  /* Project Main Colors */
  --main-clr: rgba(0, 0, 0, 0);
  --main-undertone-clr: rgba(0, 0, 0, 0);
  --secondary-clr: rgba(0, 0, 0, 0);

  /* Shades */
  --loader-background-clr: rgba(255, 255, 255, 0.6);
  --chat-background-clr: rgba(250, 250, 250, 1);
  --break-line-clr: rgba(209, 207, 207, 1);
  --text-clr: rgba(19, 19, 19, 1);
  
  /* All Defined Colors */
  --black-clr: rgba(0, 0, 0, 1);
  --white-clr: rgba(255, 255, 255, 1);
  --darkest-grey-clr: rgba(51, 51, 51, 1);
  --darker-grey-clr: rgba(48, 48, 48, 1);
  --dark-grey-clr: rgba(89, 89, 89, 1);
  --grey-clr: rgba(130, 130, 130, 1);
  --light-grey-2-clr: rgba(190, 190, 190, 1);
  --light-grey-clr: rgba(210, 210, 210, 1);
  --lighter-grey-clr: rgba(227, 227, 227, 1);
  --lighter-grey-2-clr: rgba(233, 233, 233, 1);
  --lighest-grey-clr: rgba(243, 243, 243, 1);
  --blue-clr: rgba(0, 51, 255, 1);
  --ligher-blue-clr: rgba(166, 177, 194, 1);
  --dark-blue-clr: rgba(32, 56, 105, 1);
  --faded-blue-clr: rgba(25, 53, 82, 1);
  --darker-blue-clr: rgba(18, 34, 49, 1);
  --cyan-clr: rgba(1, 255, 217, 1);
  --faded-cyan-clr: rgba(42, 188, 195, 1);
  --green-clr: rgba(213, 252, 240, 1);
  --dark-green-clr: rgba(31, 105, 98, 1);
  --red-clr: rgba(255, 0, 0, 1);
  --red-2-clr:rgba(240, 43, 45, 1);
}
@font-face {
  font-family: 'Assistant'; /*Can be any text*/
  src: url('./lib/fonts/Assistant-VariableFont_wght.ttf') format('woff');
}

@font-face {
  font-family: 'openSans-regular'; /*Can be any text*/
  src: local('openSans-Regular'), url('./lib/fonts/OpenSans-Regular.ttf') format('woff');
}
@font-face {
  font-family: 'openSans-light'; /*Can be any text*/
  src: local('openSans-Light'), url('./lib/fonts/OpenSans-Light.ttf') format('woff');
}

@font-face {
  font-family: 'openSans-extraBold'; /*Can be any text*/
  src: local('OpenSans-ExtraBold'), url('./lib/fonts/OpenSans-ExtraBold.ttf') format('woff');
}

@font-face {
  font-family: 'openSans-bold'; /*Can be any text*/
  src: local('OpenSans-Bold'), url('./lib/fonts/OpenSans-Bold.ttf') format('woff');
}
.css-fjmm3k-MuiButtonBase-root-MuiTab-root {
  /* font-family: Assistant !important; */
  text-transform: none !important;
  text-transform: initial !important;
}
.old-question-text {
  direction: rtl;
  /* text-align: right; */
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin: 15px 0 0px;
  font-style: italic;
  font-size: 22px;
}

.tags-title {
  text-align: center;
  direction: rtl;
  color: #373737;
  font-size: 18px;
  margin-top: 30px;
}
.tags-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 15px;
}
.tag-bubble {
  border-radius: 15px;
  border: solid 1px #000;
  min-width: 46px;
  height: 25px;
  padding: 2px 7px 0;
  cursor: pointer;
  font-size: 14px;
  gap: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  width: initial;
  text-align: inherit;
  color: #000;
}

.language-choose-container {
  position: absolute !important;
  top: 30px;
  right: 20px;
  height: 23px;
  width: 100px;
  text-align: center;
  font-size: 16px;
}

.margin-0 {
  margin: 0;
}

.hide {
  display: none;
}

.tool-kit-btn-mobile {
  display: none;
}

.tag-bubble:hover {
  background-color: black;
  color: white;
}

.logo-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 30px;
  flex-direction: column;
  align-items: center;
}

.did-you-know-container {
  display: flex;
}

.stick-to-bottom {
  padding-bottom: 30px;
  margin-top: 0;
}

.logo-container.with-ans {
  position: -webkit-sticky;
  position: sticky;
  padding-top: 25px;
  padding-bottom: 25px;
}

.see-more-history {
  text-decoration: underline;
  margin-left: 10px;
  font-size: 12px;
}

.explanation-text {
  direction: rtl;
  text-align: center;
  padding: 0 20px;
  font-size: 16px;

  max-width: 800px;
  margin: 0 auto;
}
.logo {
  width: 56%;
}

.links-container {
  display: flex;
  justify-content: space-evenly;
  max-width: 560px;
  margin: 0 auto;
}

.policy-link {
  cursor: pointer;
  text-align: center;
  width: auto;
  width: initial;
}
.about-us {
  cursor: pointer;
  text-decoration: underline;
}

.advertising-section {
  display: none;
}

.speak-icon {
  position: relative;
  bottom: -20px;
}

.speak-icon,
.active {
  width: 19px;
  height: 19px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.refresh-answer-icon {
  cursor: pointer;
  width: 19px;
  height: 19px;
  position: absolute;
  bottom: 10px;
  right: 50px;
}

.policy-page {
  direction: rtl;
  overflow-x: hidden;
  padding: 5px 10px;
}

.about-us-section {
  direction: rtl;
  text-align: right;
  padding: 0 10px;
  margin-top: 50px;
}

.policy-page.with-ans {
  margin-top: 100vh;
  position: relative;
  top: -20px;
}

.ans-que-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 75%;

  margin: 0px auto 0;
  height: calc(100vh - 200px);
  justify-content: flex-end;
}

.logo-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  gap: 11px;
}

.logo-onebi {
  width: 126px;
  height: 22px;
}

.Typewriter {
  direction: ltr;
  /* flex-grow: 1; */
  /* max-width: 300px; */
  position: relative;
  width: 100%;
  padding: 10px 10px 0 10px;
  min-height: 112px;
  /* font-size: 22px; */
  /* padding-right: 24px;
  padding-left: 24px; */
  color: black;
  /* margin-bottom: 15px; */
}

/* .answer {
  font-size: 22px;
  padding-right: 20px;
  padding-left: 20px;
  color: black;
} */

.like-icon-answer {
  align-self: end;
  margin-left: 6px;
  margin-top: 5px;
}

.single-ans-que-container {
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  padding: 0 22px 25px;
  margin-top: 15px;
  background-color: white;
  position: relative;
}
.single-ans-que-container h4,
p {
  color: black;
}
.like-text-answer {
  float: left;
  margin-right: 4px;
  color: gray;
  font-size: 13px;
  position: relative;
  top: 3px;
}

.answer-text {
  direction: rtl;
  /* text-align: right; */
  width: 100%;
  margin-bottom: 30px;
  flex-direction: column;
  overflow-y: auto;
  display: flex;
  /* overflow-x: hidden; */
}

.option-result-answer {
  padding: 0 30px;
}

.search-logo {
  margin-left: 4px;
  height: 40px;
  width: 48px;
}

.advertising-section {
  width: 100%;
  height: 70px;
  margin-bottom: 10px;
}

.beta-version-text {
  color: #26375b;
  margin-top: 15px;
  font-size: 12px;
}

.questions-so-far-text {
  font-size: 16px;
  color: #193552;
}

.gpt-3-span {
  cursor: pointer;
}

.gpt-desc-title {
  margin-top: 50px;
  text-align: center;
  font-size: 12px;
  color: #26375b;
}

.ad-for-desktop-new {
  margin: 20px auto;
  display: flex;
}
.ad-for-desktop {
  height: calc(100vh + 127px);
  width: 150px;
  position: absolute;
  background-color: grey;
}

.ad-for-desktop.right {
  right: 0;
}

.ad-for-desktop.top {
  width: calc(100vw - 400px);
  height: 150px;
  position: static;
  margin: 0 auto;
}
.ad-for-mobile {
  display: none;
}

.to-examples {
  margin-top: 10px;
}

.history-link {
  position: absolute;
  width: auto;
  width: initial;
  right: 20px;
  top: 50px;
}

.source-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  margin: 15px 0;
}
.Typewriter a {
  font-size: 16px;
  color: #122231;
}

.link-to-source {
  font-size: 16px;
  color: #122231;
}

@media only screen and (max-width: 1500px) {
  .ans-que-container {
    max-height: 700px;
  }
}

@media only screen and (max-width: 600px) {
  .bcg-white-mobile {
    background-color: white;
  }
  .mobile-gone {
    display: none !important;
  }

  .tool-kit-btn-mobile {
    display: inline;
    display: initial;
    position: absolute;
    left: 35px;
    top: 80px;
    z-index: 100;
  }
  .language-choose-container {
    width: 80px;
    font-size: 14px;
  }

  .ans-que-container {
    height: calc(100vh - 238px);
    max-height: none;
    max-height: initial;
  }

  .main-page-line {
    display: none;
  }

  .main-content-container {
    width: auto;
    width: initial;
    height: 100%;
    margin-top: 0;
    margin-top: initial;
  }
  .ad-for-desktop {
    display: none;
  }
  .ad-for-desktop-new {
    display: none;
  }

  .ad-for-mobile {
    position: static;
    margin: 0 auto;

    display: block;
  }
  .ad-for-mobile.loader-ad {
    height: 140px;
    width: 340px;
  }

  textarea {
    width: 65%;
  }

  /* .question-container {
    margin-top: 30px;
  } */

  .ans-que-container {
    margin-top: 40px;
    width: calc(100vw - 80px);
    margin: 40px auto 0;
    height: calc(100vh - 230px);
  }

  .answer-text {
    max-height: calc(100vh - 340px);
    font-size: 16px;
    width: calc(100vw - 80px);
  }

  .advertising-section {
    display: block;
  }

  .tags-container {
    padding: 0 10px;
  }

  .questions-so-far-text {
    font-size: 16px;
  }
  .logo {
    width: 50%;
  }
  .old-question-text {
    width: auto;
    width: initial;
  }

  .gpt-desc-title {
    font-size: 14px;
  }
  .stick-to-bottom {
    padding-bottom: 10px;
  }

  .links-container {
    flex-direction: column;
  }

  .logo-link-container {
    margin-bottom: 10px;
  }
  .tags-title {
    margin-top: 10%;
  }
  .logo-container {
    padding-top: 10px;
  }
  .history-link {
    top: 80px;
  }
}

/* ===== Actual Styles ===== */

/* ===== Horizontal Rule ===== */
.rule {
  margin: 10px 0;
  border: none;
  height: 1.5px;
  background-image: linear-gradient(to right, #f0f0f0, #c9bbae, #f0f0f0);
}

/* ===== Select Box ===== */
.sel {
  font-size: 1rem;
  display: inline-block;
  margin: 3em 2em;
  width: 350px;
  background-color: transparent;
  position: relative;
  cursor: pointer;
}

.sel::before {
  position: absolute;
  content: '\f063';
  font-family: 'FontAwesome';
  font-size: 2em;
  color: #fff;
  right: 20px;
  top: calc(50% - 0.5em);
}

.sel.active::before {
  transform: rotateX(-180deg);
}

.sel__placeholder {
  display: block;
  font-family: 'Quicksand';
  font-size: 2.3em;
  color: #838e95;
  padding: 0.2em 0.5em;
  text-align: left;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
  visibility: visible;
}

.sel.active .sel__placeholder {
  visibility: hidden;
}

.sel__placeholder::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.2em 0.5em;
  content: attr(data-placeholder);
  visibility: hidden;
}

.sel.active .sel__placeholder::before {
  visibility: visible;
}

.sel__box {
  position: absolute;
  top: calc(100% + 4px);
  left: -4px;
  display: none;
  list-style-type: none;
  text-align: left;
  font-size: 1em;
  background-color: #fff;
  width: calc(100% + 8px);
  box-sizing: border-box;
}

.sel.active .sel__box {
  display: block;
  animation: fadeInUp 500ms;
}

.sel__box__options {
  display: list-item;
  font-family: 'Quicksand';
  font-size: 1.5em;
  color: #838e95;
  padding: 0.5em 1em;
  -webkit-user-select: none;
          user-select: none;
}

.sel__box__options::after {
  content: '\f00c';
  font-family: 'FontAwesome';
  font-size: 0.5em;
  margin-left: 5px;
  display: none;
}

.sel__box__options.selected::after {
  display: inline;
}

.sel__box__options:hover {
  background-color: #ebedef;
}

/* ----- Select Box Black Panther ----- */
.sel {
  border-bottom: 4px solid rgba(0, 0, 0, 0.3);
}

.sel--black-panther {
  z-index: 3;
}

/* ----- Select Box Superman ----- */
.sel--superman {
  /*   display: none; */
  z-index: 2;
}

/* ===== Keyframes ===== */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.question-type-input {
  direction: rtl;
  resize: none;

  width: 100%;
  min-height: 112px;
  font-size: 18px;
  /*  min-height: 42px; */
  max-width: 100%;
  /* padding: 10px 10px 0 10px; */
  /* overflow: hidden; */
  position: relative;
  font-family: Assistant;
  border-radius: 10px;
  font-size: 14px;
  color: rgba(19, 19, 19, 1);
  color: var(--text-clr);

  background-color: white;
  border: 0;
  /* color: #26375b; */
}
.alignment-icon-active path {
  fill: var(--mid-green-clr);
}
.direction-icon-active path {
  stroke: var(--mid-green-clr);
}

.toolkit-flex .checkboxes-container .MuiSelect-select {
  font-size: 16px;
  min-height: 0;
  min-height: initial;
}
