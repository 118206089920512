.modal-full-screen[data-v-76b06105] {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(44, 44, 44, 0.46);
  z-index: 999;
}
.modal-upper[data-v-76b06105] {
  text-align: center;
}
.modal-upper h2[data-v-76b06105] {
  margin-bottom: 7px;
  color: var(--main-clr);
}
.modal-upper span[data-v-76b06105] {
  color: var(--text-clr);
}
.modal-container[data-v-76b06105] {
  display: flex;
  flex-direction: column;
  position: fixed;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  width: 55%;
  background-color: var(--white-clr);
  border-radius: 16px;
  border: solid 1px #979797;
  z-index: 200;
  height: auto;
  max-height: calc(100vh - 200px);
  max-width: 1000px;
  z-index: 999;
  overflow-y: auto;
}
.modal-close-btn[data-v-76b06105] {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 16px;
}
.modal-back-btn[data-v-76b06105] {
  position: absolute;
  top: 10px;
  left: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 16px;
}
.modal-content[data-v-76b06105] {
  min-width: 450px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
