tbody tr[data-v-600838c7] {
  background-color: var(--background-main-clr);
  border: 10px solid var(--chat-background-clr);
}
.checkbox[data-v-600838c7] {
  display: none;
}
tr[data-v-600838c7] {
  width: 100%;
}
thead[data-v-600838c7] {
  width: 100px;
}
.vector-modal-btn[data-v-600838c7] {
  background-color: transparent;
  border: 0;
  outline: 0;
}
.flex-vector-methods[data-v-600838c7] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}
.flex-vector-methods button[data-v-600838c7] {
  font-size: 18px;
  display: flex;
  gap: 16px;
}
thead th[data-v-600838c7] {
  /* background-color: #f0f0f0; */
  position: -webkit-sticky;
  position: sticky;
  /* font-family: 'Courier New', Courier, monospace; */
  top: 0; /* Adjust this if you have any fixed element at the top of the page */
  z-index: 2;
  background-color: var(--chat-background-clr);
  padding: 13px 10px;
}
tr img[data-v-600838c7] {
  width: 18px;
  height: 18px;
}
td[data-v-600838c7],
th[data-v-600838c7] {
  min-width: 120px;
}
table[data-v-600838c7] {
  border: 0;
  width: 100%;
  table-layout: fixed;
  font-size: 16px;
  border-collapse: collapse;
  font-family: Assistant;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  gap: 10px;
}
.table-container[data-v-600838c7] {
  /* width: 1300px; */
  /* padding-right: 20px;
    padding-left: 20px; */
  overflow: auto;
  max-height: calc(100vh - 300px);
}
td[data-v-600838c7] {
  border-right: none;
  border-left: none;
  font-size: 18px;
  /* vertical-align: top; */
  padding: 10px;
}
.icon-name-container[data-v-600838c7] {
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-family: Assistant;
  align-items: center;
  overflow: hidden;
  /* font-size: 18px; */
  /* margin-right: 20px; */
}
.icon-name-container svg[data-v-600838c7] {
  width: 24px;
  cursor: auto;
}
.file-type-icon svg[data-v-600838c7] {
  position: relative;
}
.file-type-icon svg[data-v-600838c7]:last-of-type {
  left: 17px;
}
.marked-row[data-v-600838c7] {
  background-color: var(--light-green-clr);
}
button[data-v-600838c7] {
  border: 2px solid #04aa6d;
  border-radius: 0.5rem;
  background-color: white;
  color: black;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  vertical-align: top;
  transition-duration: 0.3s;
  cursor: pointer;
}
/* button:hover {
  background-color: #04aa6d;
  color: white;
} */
.search-box-container[data-v-600838c7] {
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 20px;
  position: -webkit-sticky;
  position: sticky;
  gap: 10px;
  z-index: 1;
}
.search-input[data-v-600838c7] {
  font-weight: 600;
  color: var(--text-clr);
  border-radius: 8px;
  border-radius: var(--8px, 8px);
  outline: 0;
  border: 0;
  background-color: var(--background-second-clr);
  width: 280px;
  height: 30px;
  padding: 8px 12px;
  padding: var(--8px, 8px) var(--12px, 12px);
  gap: 4px;
  gap: var(--4-px, 4px);
}
.search-input[data-v-600838c7]::placeholder {
  color: var(--text-clr);
}
.select-box-tool-box[data-v-600838c7] {
  direction: ltr;
  font-weight: 600;
  width: 170px;
  font-family: Assistant;
  background-color: var(--background-second-clr);
  height: 30px;
  border-radius: 8px;
  /* border: solid 1px #193552; */
  outline: none;
  font-size: 14px;
  color: var(--text-clr);
  /* font-family: 'openSans-extraBold'; */
}
.more-info-icon[data-v-600838c7] {
  margin-left: 42%;
  color: var(--main-clr);
  width: 32px;
  height: 32px;
}
.ag-theme-alpine[data-v-600838c7] {
  --ag-borders: none; /* This removes all borders set via the --ag-borders variable */
}
