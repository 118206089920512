.read-or-hide-text[data-v-e8fb613c] {
    cursor: pointer;
}

/* .expand-text {
    width: '650px';
    min-width: '650px';
    max-width: '650px';
    font-weight: 400;
    text-align: 'right';
    white-space: 'pre-wrap';
} */